html {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

body {
  width: 100%;
  height: 100%;
  background: #000;
  font-family: 'GothamBook', sans-serif;
}

h1, h2, p {
  margin: 0;
}

sup {
  font-size: 60%;
}

.grid-container {
  position: relative;
  padding-right: 1.875rem;
  padding-left: 1.875rem;

  @include breakpoint(small down) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.bg {
  z-index: 0;
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
  margin: 0 !important;
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
  transition: opacity 300ms;
}

.hide-sm {
  @include breakpoint(small down) {
    display: none !important;
  }
}

::placeholder {
  color: #999;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #999;
}
