.hotlist {
  text-align: center;

  h2 {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }

  p {
    font-size: 0.938rem !important;
    line-height: 1.125rem !important;
    margin-bottom: 15px;
  }

  .row {
    margin-bottom: 45px;
  }

  .pull-left {
    @include breakpoint(small down) {
      margin-bottom: 45px;
    }
  }

  &__venue {
    display: inline-block;
    width: 48%;

    @include breakpoint(small down) {
      float: none !important;
      display: block;
      width: 100%;
    }
  }

  &__image {
    margin-bottom: 15px;
  }
}
