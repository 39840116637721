.landing {
  padding: 0 !important;
  height: 100%;
  min-height: 768px;

  @include breakpoint(small down) {
    min-height: 600px;
  }

  &__login,
  &__copy {
    position: absolute;
    left: 15px;
    bottom: 45px;
    padding: 0 30px;
    width: 100%;
    max-width: 390px;

    @include breakpoint(medium down) {
      left: 0;
      bottom: 30px;
    }
  }

  h1 {
    color: #fff;
    font-family: 'GothamBook', sans-serif !important;
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
    margin-bottom: 15px !important;
    text-align: left !important;
  }

  p {
    color: #fff;
    font-family: 'GothamBook', sans-serif !important;
    font-size: 24px !important;
    line-height: 28px !important;
    margin-bottom: 15px !important;
    white-space: nowrap;

    @include breakpoint(small down) {
      font-size: 20px !important;
      line-height: 24px !important;
      white-space: normal;
    }
  }

  &__xo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 15px;
    text-align: center;

    img {
      width: 100%;
      max-width: 400px;
      height: auto;
    }

    @include breakpoint(medium down) {
      top: 47%;
    }

    @include breakpoint(small down) {
      top: 40%;
      width: 100%;
      padding: 0 30px;
    }
  }

  &__form-input {
    margin-bottom: 15px !important;
  }

  .login-btn,
  .enter-btn {
    display: block;
    background: #c4a366;
    color: #000;
    width: 100%;
    max-width: 330px;
    height: 38px;
    border-radius: 5px;
    font-family: 'GothamBold', sans-serif;
    font-size: 1.15rem;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: #fff !important;
    }
  }
}
