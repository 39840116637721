.main {
  position: relative;
  margin-top: 65px;
  width: 100%;
  height: calc(100vh - 65px);
}

.hennessy {
  margin-bottom: 15px;
}

.bras-arme {
  position: fixed;
  top: 50%;
  margin-top: calc(-83px + 45px);
  left: -38px;

  @include breakpoint(medium down) {
    display: none;
  }
}

.section {
  position: relative;
  margin: 0 auto;
  padding-bottom: 45px;

  @include breakpoint(medium down) {
    background-position: 25% top;
  }

  @include breakpoint(small down) {
    background-position: 0 top;
  }

  &__inner {
    border: 1px solid #fff;
    padding: 35px;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    z-index: 1;

    @include breakpoint(medium down) {
      padding: 35px 20px;
    }
  }

  &__logo {
    height: 75px;
  }

  &__image {
    margin-bottom: 15px;
  }

  h1 {
    font-family: 'GothamBold', sans-serif;
    font-size: 1.625rem;
    line-height: 1.875rem;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(small down) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }

  h2 {
    font-family: 'GothamBold', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  h3 {
    font-family: 'GothamBold', sans-serif;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin: 30px 0 15px;
    text-transform: uppercase;
  }

  h4 {
    font-family: 'GothamBold', sans-serif;
    font-size: .938rem;
    line-height: 1.15rem;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .row {
    overflow: hidden;
    width: 100%;
    margin-bottom: 15px;
  }

  .form-nav {
    text-align: right;

    #form-prev,
    #form-next {
      cursor: pointer;
    }

    span {
      margin: 0 10px;
    }
  }

  .feedback {
    display: none;
    font-family: 'GothamBold', sans-serif;
    font-size: 1.15rem;
    line-height: 1.5rem;
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  p, ul {
    font-family: 'GothamBook', sans-serif;
    font-size: .938rem;
    line-height: 1.25rem;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 5px;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    b {
      font-family: 'GothamBold', sans-serif;
      font-weight: normal;
    }
  }

  .note {
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .radio {
    display: inline-block;
  }

  label {
    color: #fff;
    font-family: 'GothamBold', sans-serif;
    font-size: .938rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  form span {
    font-family: 'GothamBook', sans-serif;
    font-size: .875rem;
    margin-left: 5px;
    margin-right: 10px;
  }

  input,
  select {
    font-family: 'GothamBook', sans-serif;
    font-size: .875rem;
    margin: 5px 0 0;
  }

  input[type=submit],
  .add-request-btn,
  .submit-btn {
    background: #c4a366;
    border: none;
    color: #000;
    width: 100%;
    padding: 16px 32px;
    border-radius: 5px;
    font-family: 'GothamBold', sans-serif;
    font-size: .938rem;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }

    &:disabled {
      opacity: .5;
      color: #000 !important;
      cursor: not-allowed !important;
    }
  }

  #diningRequest,
  #loungeRequest,
  #dayClubRequest,
  #nightClubRequest  {
    display: none;
  }

  .pull-left {
    float: left;
    width: 48%;

    @include breakpoint(small down) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .pull-right {
    float: right;
    width: 48%;

    @include breakpoint(small down) {
      width: 100%;
    }
  }
}
