.navbar {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  width: 100%;
  height: 65px;
  z-index: 1000;
  text-align: center;

  &__cell {
    position: relative;
    width: 100%;
    height: 65px;
  }

  &__logo {
    width: auto;
    height: 35px;
    display: none;

    @include breakpoint(small down) {
      display: block;
    }
  }

  &__label {
    display: inline-block;
    float: left;
    color: #fff;
    font-family: 'GothamBook', sans-serif;
    font-size: 1rem;
    line-height: 20px;
    padding-right: 10px;
  }

  &__toggle {
    float: right;
    border: none;
    outline: 0;
    padding: 0;
    margin-top: 22px;
    background: none;
    cursor: pointer;
    display: none;

    @include breakpoint(small down) {
      display: block;
    }

    &-icon {
      float: right;
      width: 25px;
      height: 20px;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0);
        transition: .25s ease-in-out;
      }

      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2),
      span:nth-child(3) {
        top: 9px;
      }

      span:nth-child(4) {
        top: 18px;
      }
    }

    &--open{
      span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9997;
    transition: .15s linear all;

    @include breakpoint(small down) {
      top: 65px;
      background: #000;
    }

    &-logo {
      float: left;
      width: auto;
      margin-top: 13px;
      height: 50px;
      display: block;

      @include breakpoint(small down) {
        display: none;
      }
    }

    &-list {
      list-style: none;
      margin: 0;
      height: 65px;
      line-height: 65px;
      transition: .25s ease-in-out;
      overflow: hidden;
      text-align: center;

      @include breakpoint(small down) {
        text-align: right;
        height: 0;
        line-height: normal;
      }

      &--open {
        height: 182px;
      }

      &-item {
        display: inline-block;
        padding: 0;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        @include breakpoint(medium down) {
          margin-right: 20px;
        }

        @include breakpoint(small down) {
          display: block;
          padding: 12px 0;
          margin-right: 0;
        }
      }

      &-item-link {
        color: #fff;
        font-family: 'GothamBold', sans-serif;
        font-size: 1.125rem;
        line-height: 1.125rem;
        outline: none;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          font-size: 1rem;
        }

        &:hover {
          color: #b4975a !important;
        }

        &:visited,
        &:focus,
        &:active {
          color: #fff;
        }
      }

      &-item-link.active {
        color: #b4975a !important;

        @include breakpoint(small down) {
          border-right: 3px solid #b4975a;
          padding-right: 10px;
        }
      }
    }
  }
}
