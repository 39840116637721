.itinerary {
  text-align: left;

  p {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &__day {
    h2 {
      color: #b4975a;
      font-family: GothamBold,Arial,"Helvetica Neue",Helvetica,sans-serif;
      font-size: 1.5rem !important;
      line-height: 1.75rem !important;
      margin-bottom: 15px !important;
      text-transform: uppercase;

      @include breakpoint(small down) {
        font-size: 1.25rem !important;
        line-height: 1.5rem !important;
      }
    }

    p {
      font-family: GothamBook,Arial,"Helvetica Neue",Helvetica,sans-serif;
      font-size: 1rem !important;
      line-height: 1.25rem !important;
      margin-bottom: 10px !important;
    }
  }

  &__event {
    margin-bottom: 30px;
  }

  &__day-hr {
    margin: 2rem auto;
  }
}
