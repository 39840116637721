@import 'settings';
@import '~foundation-sites/scss/foundation';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// mixins
@import 'mixins/container-border';
@import 'mixins/flex';
@import 'mixins/avatar';
@import 'mixins/helpers';
@import 'mixins/psuedo';
@import 'mixins/push-center';
@import 'mixins/sidelines';
@import 'mixins/transition';
@import 'mixins/zindex';

// global styles
@import 'global/layout';
@import 'global/typography';

@import 'landing';
@import 'main';
@import 'main-nav';
@import 'registration';
@import 'itinerary';
@import 'hotlist';

.app {
  width: 100%;
  height: 100%;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}


.section {
  position: relative;
}
